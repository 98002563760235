/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Ethiopic:wght@500&display=swap');
 /* test area  */
 
 
 /* end of test area */



 .mat-button-disabled, .mat-flat-button-disabled, .mat-raised-button-disabled, .mat-icon-button-disabled, .mat-stroked-button-disabled {
  color: #ccc; /* Grey out the text */
  background-color: #e0e0e0; /* Light grey background */
  cursor: not-allowed; /* Show 'not-allowed' cursor */
}


html,body{
    font-family:   "Noto Serif Ethiopic" , sans-serif;
    background-color: #d6d6d6;
    font-size: 16px;
    font-weight: 550;
 
    padding: 0;
    margin: 0;
 
     height: 100%;
   display: flex;
  flex-direction: column;
   
}
 
main {
  flex: 1;
}
.adv-button{
        background-color: #00a651;
         color: white;
         font-size: 1em;
         min-width: 150px;
}
.mat-form-field-ripple {
    background-color:  #00a651 !important;
  }
  mat-form-field span.mat-form-field-label-wrapper label {
    color: #00a651 !important;
  }
 /*inner circle color change*/
   .mat-radio-button.mat-accent .mat-radio-inner-circle {
    background-color:  #00a651 !important;  
}  
 
/* outer ring color change */

.flex_row_center_center{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex_column_center_center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex_column_start_start{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex_row_space_between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}
.flex_row_start_start_wrap{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  
}
.flex_row_start_start{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
   
}
/* .flex_column_center_center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.geez {
  font-family:   "Noto Serif Ethiopic" , sans-serif;
  font-size: 1.1em;
}